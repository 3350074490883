// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Sentry = require("../../Sentry.bs.js");
var RelayEnv = require("../../../RelayEnv.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CurrentUser__Types = require("./CurrentUser__Types.bs.js");
var ClientAccess__Types__Avatar = require("../../ClientAccess/types/ClientAccess__Types__Avatar.bs.js");
var CurrentUser__Context__QueryRelay = require("./CurrentUser__Context__QueryRelay.bs.js");

var initialLegacyValue_plan = CurrentUser__Types.Plan.defaultPlan;

var initialLegacyValue = {
  userRole: undefined,
  chapter: undefined,
  user: undefined,
  role: undefined,
  accountOwner: undefined,
  plan: initialLegacyValue_plan,
  tanStaff: false,
  terminatedAccount: false,
  isLoggedIn: false,
  initialized: false,
  setUser: undefined,
  reload: undefined
};

var legacyUnauthenticatedValue_plan = CurrentUser__Types.Plan.defaultPlan;

var legacyUnauthenticatedValue = {
  userRole: undefined,
  chapter: undefined,
  user: undefined,
  role: undefined,
  accountOwner: undefined,
  plan: legacyUnauthenticatedValue_plan,
  tanStaff: false,
  terminatedAccount: false,
  isLoggedIn: false,
  initialized: true,
  setUser: undefined,
  reload: undefined
};

var context = React.createContext("NotCalled");

var make = context.Provider;

var Provider = {
  make: make
};

var _map = {"IDLE":"IDLE","INITIALIZING":"INITIALIZING","INITIALIZED":"INITIALIZED"};

var _revMap = {"IDLE":"IDLE","INITIALIZING":"INITIALIZING","INITIALIZED":"INITIALIZED"};

function reduxInitStatusToJs(param) {
  return _map[param];
}

function reduxInitStatusFromJs(param) {
  return _revMap[param];
}

function CurrentUser__Context$Provider$RelaySyncComponent(props) {
  var setValue = props.setValue;
  var initializationStatus = props.initializationStatus;
  var token = props.token;
  var data = CurrentUser__Context__QueryRelay.useDecoded(props.queryRef);
  var environment = RescriptRelay.useEnvironmentFromContext();
  var updateState = async function (param) {
    await CurrentUser__Context__QueryRelay.Query.fetchPromised(environment, undefined, undefined, "network-only", undefined);
  };
  React.useEffect((function (param) {
          var exit = 0;
          var exit$1 = 0;
          if (token !== undefined || initializationStatus !== "INITIALIZED") {
            exit$1 = 2;
          } else {
            exit = 1;
          }
          if (exit$1 === 2) {
            if (data.TAG === "Ok") {
              var match = data._0;
              if (typeof match !== "object") {
                exit = 1;
              } else if (match.TAG === "Agent") {
                var isImpersonated = match.isImpersonated;
                var agent = match.agent;
                Curry._1(setValue, (function (param) {
                        return {
                                TAG: "Agent",
                                _0: {
                                  chapter: agent.chapter,
                                  user: agent.user,
                                  role: agent.role,
                                  accountOwner: agent.accountOwner,
                                  plan: agent.plan,
                                  tanStaff: agent.tanStaff,
                                  permissions: agent.permissions,
                                  terminatedAccount: agent.accountStatus === "TERMINATED",
                                  isImpersonated: isImpersonated,
                                  setUser: (function (user) {
                                      Curry._1(setValue, (function (x) {
                                              if (typeof x !== "object") {
                                                return "Error";
                                              }
                                              if (x.TAG !== "Agent") {
                                                return "Error";
                                              }
                                              var prev = x._0;
                                              return {
                                                      TAG: "Agent",
                                                      _0: {
                                                        chapter: prev.chapter,
                                                        user: user,
                                                        role: prev.role,
                                                        accountOwner: prev.accountOwner,
                                                        plan: prev.plan,
                                                        tanStaff: prev.tanStaff,
                                                        permissions: prev.permissions,
                                                        terminatedAccount: prev.terminatedAccount,
                                                        isImpersonated: prev.isImpersonated,
                                                        setUser: prev.setUser,
                                                        reload: prev.reload
                                                      }
                                                    };
                                            }));
                                    }),
                                  reload: (function (param) {
                                      return updateState();
                                    })
                                }
                              };
                      }));
              } else {
                var isImpersonated$1 = match.isImpersonated;
                var match$1 = match.client;
                var hasAnAutonotifyBuyerNeed = match$1.hasAnAutonotifyBuyerNeed;
                var status = match$1.status;
                var agent$1 = match$1.agent;
                var avatar = match$1.avatar;
                var email = match$1.email;
                var lastName = match$1.lastName;
                var firstName = match$1.firstName;
                var id = match$1.id;
                Curry._1(setValue, (function (param) {
                        return {
                                TAG: "Client",
                                _0: {
                                  id: id,
                                  firstName: firstName,
                                  lastName: lastName,
                                  email: email,
                                  avatar: ClientAccess__Types__Avatar.make(avatar, firstName, lastName),
                                  agent: agent$1,
                                  hasAnAutonotifyBuyerNeed: hasAnAutonotifyBuyerNeed,
                                  isImpersonated: isImpersonated$1,
                                  setProfileInfo: (function (param) {
                                      var avatar = param.avatar;
                                      var email = param.email;
                                      var lastName = param.lastName;
                                      var firstName = param.firstName;
                                      Curry._1(setValue, (function (x) {
                                              if (typeof x !== "object") {
                                                return "Error";
                                              }
                                              if (x.TAG !== "Client") {
                                                return "Error";
                                              }
                                              var prev = x._0;
                                              return {
                                                      TAG: "Client",
                                                      _0: {
                                                        id: prev.id,
                                                        firstName: firstName,
                                                        lastName: lastName,
                                                        email: email,
                                                        avatar: avatar,
                                                        agent: prev.agent,
                                                        hasAnAutonotifyBuyerNeed: prev.hasAnAutonotifyBuyerNeed,
                                                        isImpersonated: prev.isImpersonated,
                                                        setProfileInfo: prev.setProfileInfo,
                                                        reload: prev.reload,
                                                        status: prev.status
                                                      }
                                                    };
                                            }));
                                    }),
                                  reload: (function (param) {
                                      return updateState();
                                    }),
                                  status: status
                                }
                              };
                      }));
              }
            } else {
              Curry._1(setValue, (function (param) {
                      return "Error";
                    }));
            }
          }
          if (exit === 1) {
            Curry._1(setValue, (function (param) {
                    return "Unauthenticated";
                  }));
          }
          
        }), [
        data,
        token,
        initializationStatus
      ]);
  return null;
}

var RelaySyncComponent = {
  make: CurrentUser__Context$Provider$RelaySyncComponent
};

function CurrentUser__Context$Provider$ComponentRelay(props) {
  var initializationStatus = props.initializationStatus;
  var token = props.token;
  var match = React.useState(function (param) {
        return "NotCalled";
      });
  var setValue = match[1];
  var value = match[0];
  var match$1 = CurrentUser__Context__QueryRelay.Query.useLoader();
  var loadQuery = match$1[1];
  var queryRef = match$1[0];
  React.useEffect((function (param) {
          if (token !== undefined) {
            if (initializationStatus === "INITIALIZED") {
              Sentry.addBreadcrumb("Refetching user context", "info", "auth");
              if (typeof value !== "object" && value === "NotCalled") {
                Curry._1(setValue, (function (param) {
                        return "Loading";
                      }));
              }
              Curry._4(loadQuery, undefined, undefined, undefined, undefined);
            }
            
          } else if (initializationStatus === "INITIALIZED") {
            Curry._1(setValue, (function (param) {
                    return "Unauthenticated";
                  }));
          }
          
        }), [
        token,
        initializationStatus
      ]);
  return JsxRuntime.jsxs(make, {
              value: value,
              children: [
                JsxRuntime.jsx(React.Suspense, {
                      children: Caml_option.some(queryRef !== undefined ? JsxRuntime.jsx(CurrentUser__Context$Provider$RelaySyncComponent, {
                                  token: token,
                                  initializationStatus: initializationStatus,
                                  setValue: setValue,
                                  queryRef: Caml_option.valFromOption(queryRef)
                                }) : null),
                      fallback: Caml_option.some(null)
                    }),
                props.children
              ]
            });
}

var ComponentRelay = {
  make: CurrentUser__Context$Provider$ComponentRelay
};

function CurrentUser__Context$Provider$WithRelay(props) {
  return JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
              environment: RelayEnv.environment,
              children: JsxRuntime.jsx(CurrentUser__Context$Provider$ComponentRelay, {
                    children: props.children,
                    token: Caml_option.nullable_to_opt(props.token),
                    initializationStatus: _revMap[props.initializationStatus]
                  })
            });
}

var WithRelay = {
  make: CurrentUser__Context$Provider$WithRelay
};

var Provider$1 = {
  Provider: Provider,
  reduxInitStatusToJs: reduxInitStatusToJs,
  reduxInitStatusFromJs: reduxInitStatusFromJs,
  RelaySyncComponent: RelaySyncComponent,
  ComponentRelay: ComponentRelay,
  WithRelay: WithRelay
};

var QueryRelay;

var initialValue = "NotCalled";

var currentUserProvider = CurrentUser__Context$Provider$WithRelay;

exports.QueryRelay = QueryRelay;
exports.initialLegacyValue = initialLegacyValue;
exports.legacyUnauthenticatedValue = legacyUnauthenticatedValue;
exports.initialValue = initialValue;
exports.context = context;
exports.Provider = Provider$1;
exports.currentUserProvider = currentUserProvider;
/* context Not a pure module */
