// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Scalars = require("../../../scalars/Scalars.bs.js");
var DateFnsTz = require("../DateFns/DateFnsTz.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CurrentUserContextQueryRelayQuery_graphql = require("../../../__generated__/CurrentUserContextQueryRelayQuery_graphql.bs.js");
var CurrentUserContextQueryRelay_agent_graphql = require("../../../__generated__/CurrentUserContextQueryRelay_agent_graphql.bs.js");
var CurrentUserContextQueryRelay_clientAccount_graphql = require("../../../__generated__/CurrentUserContextQueryRelay_clientAccount_graphql.bs.js");
var CurrentUserContextQueryRelayLegacyUser_agent_graphql = require("../../../__generated__/CurrentUserContextQueryRelayLegacyUser_agent_graphql.bs.js");

var membershipStatusV5_decode = CurrentUserContextQueryRelayLegacyUser_agent_graphql.Utils.membershipStatusV5_decode;

var convertFragment = CurrentUserContextQueryRelayLegacyUser_agent_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CurrentUserContextQueryRelayLegacyUser_agent_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CurrentUserContextQueryRelayLegacyUser_agent_graphql.node, convertFragment);
}

var UserFragment_membershipStatusV5_fromString = CurrentUserContextQueryRelayLegacyUser_agent_graphql.Utils.membershipStatusV5_fromString;

var UserFragment = {
  membershipStatusV5_decode: membershipStatusV5_decode,
  membershipStatusV5_fromString: UserFragment_membershipStatusV5_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function decodeUser(user) {
  var emailAddress = user.emailAddress;
  var account = user.account;
  if (emailAddress === undefined) {
    return {
            TAG: "Error",
            _0: "Error decoding user"
          };
  }
  var firstName = user.firstName;
  if (firstName === undefined) {
    return {
            TAG: "Error",
            _0: "Error decoding user"
          };
  }
  var lastName = user.lastName;
  if (lastName === undefined) {
    return {
            TAG: "Error",
            _0: "Error decoding user"
          };
  }
  var membershipStatus = user.membershipStatus;
  if (membershipStatus === undefined) {
    return {
            TAG: "Error",
            _0: "Error decoding user"
          };
  }
  var membershipStatusDecoded = Curry._1(membershipStatusV5_decode, membershipStatus);
  if (membershipStatusDecoded !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              id: user.id,
              avatar: user.profilePhoto,
              firstName: firstName,
              lastName: lastName,
              displayName: user.displayName,
              company: user.brokerage,
              email: Scalars.EmailAddress.toString(Caml_option.valFromOption(emailAddress)),
              phone: user.phone,
              account: account !== undefined ? ({
                    id: account.databaseId
                  }) : undefined,
              membershipStatus: membershipStatusDecoded
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Error decoding required attributes for user. Decoded: " + Core__Option.getOr(JSON.stringify({
                      membershipStatus: Core__Option.isSome(membershipStatusDecoded)
                    }), "-")
          };
  }
}

var accountMemberRole_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.accountMemberRole_decode;

var accountStatusV5_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.accountStatusV5_decode;

var agentPermissions_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.agentPermissions_decode;

var planFeature_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.planFeature_decode;

var planHandleV5_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.planHandleV5_decode;

var planType_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.planType_decode;

var convertFragment$1 = CurrentUserContextQueryRelay_agent_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CurrentUserContextQueryRelay_agent_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CurrentUserContextQueryRelay_agent_graphql.node, convertFragment$1);
}

var AgentFragment_accountMemberRole_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.accountMemberRole_fromString;

var AgentFragment_accountStatusV5_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.accountStatusV5_fromString;

var AgentFragment_agentPermissions_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.agentPermissions_fromString;

var AgentFragment_onOrOffV5_decode = CurrentUserContextQueryRelay_agent_graphql.Utils.onOrOffV5_decode;

var AgentFragment_onOrOffV5_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.onOrOffV5_fromString;

var AgentFragment_planFeature_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.planFeature_fromString;

var AgentFragment_planHandleV5_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.planHandleV5_fromString;

var AgentFragment_planType_fromString = CurrentUserContextQueryRelay_agent_graphql.Utils.planType_fromString;

var AgentFragment = {
  accountMemberRole_decode: accountMemberRole_decode,
  accountMemberRole_fromString: AgentFragment_accountMemberRole_fromString,
  accountStatusV5_decode: accountStatusV5_decode,
  accountStatusV5_fromString: AgentFragment_accountStatusV5_fromString,
  agentPermissions_decode: agentPermissions_decode,
  agentPermissions_fromString: AgentFragment_agentPermissions_fromString,
  onOrOffV5_decode: AgentFragment_onOrOffV5_decode,
  onOrOffV5_fromString: AgentFragment_onOrOffV5_fromString,
  planFeature_decode: planFeature_decode,
  planFeature_fromString: AgentFragment_planFeature_fromString,
  planHandleV5_decode: planHandleV5_decode,
  planHandleV5_fromString: AgentFragment_planHandleV5_fromString,
  planType_decode: planType_decode,
  planType_fromString: AgentFragment_planType_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function useDecoded(fragmentRefs) {
  var agent = useOpt$1(fragmentRefs);
  var tmp;
  if (agent !== undefined) {
    var match = agent.membership.account.owner;
    if (match !== undefined) {
      var match$1 = match.agent;
      tmp = match$1 !== undefined ? Caml_option.some(match$1.fragmentRefs) : undefined;
    } else {
      tmp = undefined;
    }
  } else {
    tmp = undefined;
  }
  var accountOwner = Core__Option.map(useOpt(tmp), decodeUser);
  var user = Core__Option.map(useOpt(agent !== undefined ? Caml_option.some(agent.fragmentRefs) : undefined), decodeUser);
  if (agent === undefined) {
    return {
            TAG: "Error",
            _0: "Error decoding agent"
          };
  }
  var match$2 = agent.membership;
  var match$3 = match$2.account;
  var match$4 = match$3.subscription_.plan;
  var defaultChapter = agent.defaultChapter;
  var defaultToOff = function (onOrOff) {
    if (onOrOff === "ON") {
      return "ON";
    } else {
      return "OFF";
    }
  };
  var match$5 = defaultChapter.preferences;
  var chapter_id = defaultChapter.databaseId;
  var chapter_preferences = match$5 !== undefined ? ({
        granularNotificationSettings: defaultToOff(match$5.granularNotificationSettings),
        improvedLocations: defaultToOff(match$5.improvedLocations),
        mlsComingSoon: defaultToOff(match$5.mlsComingSoon),
        payingChapter: defaultToOff(match$5.payingChapter),
        anonymousMatching: defaultToOff(match$5.anonymousMatching),
        hideChapterStats: defaultToOff(match$5.hideChapterStats),
        defaultToChapterPreferenceAreas: defaultToOff(match$5.defaultToChapterPreferenceAreas)
      }) : undefined;
  var chapter_timeZone = Core__Option.map(defaultChapter.timezone, (function (v) {
          return DateFnsTz.Timezone.make(v);
        }));
  var chapter_displayName = defaultChapter.displayName;
  var chapter = {
    id: chapter_id,
    preferences: chapter_preferences,
    timeZone: chapter_timeZone,
    displayName: chapter_displayName
  };
  var roleDecoded = Curry._1(accountMemberRole_decode, match$2.role);
  var planHandleDecoded = Curry._1(planHandleV5_decode, match$4.handle);
  var planTypeDecoded = Curry._1(planType_decode, match$4.type_);
  var accountStatus = Curry._1(accountStatusV5_decode, match$3.accountStatus);
  if (roleDecoded !== undefined && planHandleDecoded !== undefined && planTypeDecoded !== undefined && accountStatus !== undefined) {
    var featuresDecoded = Core__Array.keepSome(Core__Option.getOr(match$4.features, []).map(Curry.__1(planFeature_decode)));
    var plan_onProFree = planHandleDecoded === "PRO_FREE";
    var plan_features = Core__List.fromArray(featuresDecoded);
    var plan = {
      type_: planTypeDecoded,
      handle: planHandleDecoded,
      onProFree: plan_onProFree,
      features: plan_features
    };
    var permissions = Core__Array.filterMap(Core__Option.getOr(agent.permissions, []), Curry.__1(agentPermissions_decode));
    if (accountOwner !== undefined && accountOwner.TAG === "Ok" && user !== undefined && user.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                chapter: chapter,
                user: user._0,
                accountOwner: accountOwner._0,
                role: roleDecoded,
                plan: plan,
                permissions: permissions,
                tanStaff: agent.tanStaff,
                accountStatus: accountStatus
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: "Error decoding user and/or account owner"
            };
    }
  }
  return {
          TAG: "Error",
          _0: "Error decoding needed attributes for session. Decoded: " + Core__Option.getOr(JSON.stringify({
                    role: Core__Option.isSome(roleDecoded),
                    planHandle: Core__Option.isSome(planHandleDecoded),
                    planType: Core__Option.isSome(planTypeDecoded),
                    accountStatus: Core__Option.isSome(accountStatus)
                  }), "-")
        };
}

var LoggedInAgent = {
  AgentFragment: AgentFragment,
  useDecoded: useDecoded
};

var clientAccountStatusV5_decode = CurrentUserContextQueryRelay_clientAccount_graphql.Utils.clientAccountStatusV5_decode;

var convertFragment$2 = CurrentUserContextQueryRelay_clientAccount_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(CurrentUserContextQueryRelay_clientAccount_graphql.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CurrentUserContextQueryRelay_clientAccount_graphql.node, convertFragment$2);
}

var ClientAccountFragment_clientAccountStatusV5_fromString = CurrentUserContextQueryRelay_clientAccount_graphql.Utils.clientAccountStatusV5_fromString;

var ClientAccountFragment = {
  clientAccountStatusV5_decode: clientAccountStatusV5_decode,
  clientAccountStatusV5_fromString: ClientAccountFragment_clientAccountStatusV5_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2
};

function useDecoded$1(fragmentRefs) {
  var client = useOpt$2(fragmentRefs);
  var tmp;
  if (client !== undefined) {
    var match = client.agent;
    tmp = match !== undefined ? Caml_option.some(match.fragmentRefs) : undefined;
  } else {
    tmp = undefined;
  }
  var agent = Core__Option.map(useOpt(tmp), decodeUser);
  if (agent === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  if (agent.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  if (client === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  var clientEmail = client.clientEmail;
  if (clientEmail === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  var firstName = client.firstName;
  if (firstName === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  var lastName = client.lastName;
  if (lastName === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  var status = client.status;
  if (status === undefined) {
    return {
            TAG: "Error",
            _0: "Failed decoding client."
          };
  }
  var avatar = client.avatar;
  var statusDecoded = Curry._1(clientAccountStatusV5_decode, status);
  if (statusDecoded !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              id: client.id,
              firstName: firstName,
              lastName: lastName,
              email: Scalars.EmailAddress.toString(Caml_option.valFromOption(clientEmail)),
              avatar: avatar !== undefined ? ({
                    id: avatar.id,
                    key: avatar.key,
                    fileName: "<TODO>",
                    contentType: "<TODO>"
                  }) : undefined,
              agent: agent._0,
              status: statusDecoded,
              hasAnAutonotifyBuyerNeed: Core__Option.isSome(client.agentBuyerNeed)
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Error decoding required attributes for client. Decoded: " + Core__Option.getOr(JSON.stringify({
                      status: Core__Option.isSome(statusDecoded)
                    }), "-")
          };
  }
}

var LoggedInClient = {
  ClientAccountFragment: ClientAccountFragment,
  useDecoded: useDecoded$1
};

var convertVariables = CurrentUserContextQueryRelayQuery_graphql.Internal.convertVariables;

var convertResponse = CurrentUserContextQueryRelayQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = CurrentUserContextQueryRelayQuery_graphql.Internal.convertWrapRawResponse;

function use$3(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, CurrentUserContextQueryRelayQuery_graphql.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, CurrentUserContextQueryRelayQuery_graphql.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(CurrentUserContextQueryRelayQuery_graphql.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(CurrentUserContextQueryRelayQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(CurrentUserContextQueryRelayQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(CurrentUserContextQueryRelayQuery_graphql.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$3,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useDecoded$2(queryRef) {
  var data = usePreloaded(queryRef);
  var match = data.sessionV5;
  var tmp;
  if (match !== undefined) {
    var match$1 = match.authenticatedUser;
    tmp = match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "ClientAccountV5" ? Caml_option.some(match$1.VAL.fragmentRefs) : undefined;
  } else {
    tmp = undefined;
  }
  var clientAccount = useDecoded$1(tmp);
  var match$2 = data.sessionV5;
  var tmp$1;
  if (match$2 !== undefined) {
    var match$3 = match$2.authenticatedUser;
    tmp$1 = match$3 !== undefined && typeof match$3 === "object" && match$3.NAME === "Agent" ? Caml_option.some(match$3.VAL.fragmentRefs) : undefined;
  } else {
    tmp$1 = undefined;
  }
  var agentAccount = useDecoded(tmp$1);
  var match$4 = data.sessionV5;
  var result;
  var exit = 0;
  if (match$4 !== undefined) {
    if (match$4.isAuthenticated) {
      var isImpersonated = match$4.isImpersonated;
      if (clientAccount.TAG === "Ok") {
        result = {
          TAG: "Ok",
          _0: {
            TAG: "Client",
            client: clientAccount._0,
            isImpersonated: isImpersonated
          }
        };
      } else if (agentAccount.TAG === "Ok") {
        result = {
          TAG: "Ok",
          _0: {
            TAG: "Agent",
            agent: agentAccount._0,
            isImpersonated: isImpersonated
          }
        };
      } else {
        exit = 1;
      }
    } else {
      result = {
        TAG: "Ok",
        _0: "Unauthenticated"
      };
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    result = clientAccount.TAG === "Ok" ? (
        agentAccount.TAG === "Ok" ? ({
              TAG: "Error",
              _0: "Error decoding session"
            }) : ({
              TAG: "Error",
              _0: agentAccount._0
            })
      ) : ({
          TAG: "Error",
          _0: clientAccount._0
        });
  }
  var tmp$2;
  tmp$2 = result.TAG === "Ok" ? Core__Option.getOr(JSON.stringify(result._0), "Ok") : "Error";
  return React.useMemo((function (param) {
                return result;
              }), [tmp$2]);
}

exports.UserFragment = UserFragment;
exports.decodeUser = decodeUser;
exports.LoggedInAgent = LoggedInAgent;
exports.LoggedInClient = LoggedInClient;
exports.Query = Query;
exports.useDecoded = useDecoded$2;
/* react Not a pure module */
