// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");

var closedChapters = {
  hd: 101,
  tl: {
    hd: 102,
    tl: /* [] */0
  }
};

function isChapterClosed(chapterId) {
  return Core__List.some(closedChapters, (function (x) {
                return x === chapterId;
              }));
}

exports.closedChapters = closedChapters;
exports.isChapterClosed = isChapterClosed;
/* No side effect */
