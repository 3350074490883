// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var AvatarSrcUrl = require("../../../utils/AvatarSrcUrl.bs.js");
var TailwindMerge = require("tailwind-merge");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AgentProfilePhoto_agent_graphql = require("../../../__generated__/AgentProfilePhoto_agent_graphql.bs.js");

var convertFragment = AgentProfilePhoto_agent_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AgentProfilePhoto_agent_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AgentProfilePhoto_agent_graphql.node, convertFragment);
}

var AgentProfilePhotoFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AgentProfilePhoto$Avatar(props) {
  var __className = props.className;
  var __url = props.url;
  var url = __url !== undefined ? __url : AvatarSrcUrl.place_holder;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("img", {
              className: TailwindMerge.twMerge([
                    "rounded-full w-[60px] h-[60px] object-contain bg-gray-100",
                    className
                  ]),
              alt: props.alt,
              src: url
            });
}

var Avatar = {
  make: AgentProfilePhoto$Avatar
};

function AgentProfilePhoto$WithoutErrorBoundary(props) {
  var match = RescriptRelay_Fragment.useFragment(AgentProfilePhoto_agent_graphql.node, convertFragment, props.profilePhoto);
  var agentProfilePhoto = match.agentProfilePhoto;
  var url = agentProfilePhoto !== undefined ? agentProfilePhoto.url : AvatarSrcUrl.place_holder;
  return JsxRuntime.jsx(AgentProfilePhoto$Avatar, {
              alt: match.displayName + "'s profile picture",
              url: url,
              className: props.className
            });
}

var WithoutErrorBoundary = {
  make: AgentProfilePhoto$WithoutErrorBoundary
};

function AgentProfilePhoto(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "agent profile photo",
              children: JsxRuntime.jsx(AgentProfilePhoto$WithoutErrorBoundary, {
                    profilePhoto: props.profilePhoto,
                    className: props.className
                  })
            });
}

var make = AgentProfilePhoto;

exports.AgentProfilePhotoFragment = AgentProfilePhotoFragment;
exports.Avatar = Avatar;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* AvatarSrcUrl Not a pure module */
